.qrcode-block{
  padding: 40px;
  margin-top: 4rem;
  margin-bottom: 4rem;
  background:$global-inverse-color;
  color:#000000;
  h1,h2,h3,h4,h5,h6{
    color:#000000;
    font-weight: 700;
  }
  p,ul,ol{
    color:inherit;
  }
  .qrcode {
    max-width: 250px;
    border: 1px solid #707070;
    margin:0 auto;
    @media screen and (min-width:$breakpoint-medium){
      width: 100%;
    }
  }
}