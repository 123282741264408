@mixin hook-button(){
  &.uk-button-primary{
    padding: 11px 56px;
  }
}
@mixin hook-button-default(){
  padding:11px 32px;
  font-weight: bold;
}
@mixin hook-button-primary(){
  font-weight: bold;
  text-transform: none;
  background:linear-gradient(149deg, rgba(0,255,185,1) 0%, rgba(0,230,220,1) 100%);
  &:hover{
    background:transparent;
  }
}

