footer{
  margin-block-start: 4rem;
  ul{
    margin: 0;
    padding:0;
  }
  li{
    list-style-type: none;
  }
  a{
    color:$global-inverse-color;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus{
      color:$si-teal2;
      text-decoration: underline;
    }
  }
  .footer-links{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    //justify-content:space-between;
    justify-content: end;
  }
  .footer-links > li{
    //width:20%;
  }
  .footer-links > li > a{
    //padding:11px 40px 11px 0;
    padding:0 0 0 40px;
    display:inline-block;
    font-size: 0.75rem;
    text-decoration: none;
    @media screen and (min-width: $breakpoint-small){
      font-size:1rem;
    }
  }
  .footer-links > li:first-child > a{
    //padding:11px 32px 11px 0;
  }
  .uk-button,
  .uk-button-default{
    font-size:1rem;
  }
  div.uk-text-right{
    text-align: center !important;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    @media screen and (min-width: $breakpoint-small){
      text-align: right !important;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  .uk-container:nth-child(2){
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    @media screen and (min-width: $breakpoint-small){
      margin-block-start: 3.5rem;
      margin-block-end: 3.5rem;
    }

  }
  .footer-social{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (min-width: $breakpoint-small){
      justify-content: start;
    }
  }
  .footer-social > li > a{
    padding:0 1rem;
  }
  .footer-social > li:last-child > a{
    padding:0 0 0 1rem;
  }
  .footer-copyright{
    flex-direction: column;
    font-size: 0.75rem;
    > div{
      padding:0.5rem;
      text-align: center;
    }
    @media screen and (min-width: $breakpoint-small){
      flex-direction: row;
      font-size: 1rem;
      > div{
        padding:0;
        text-align: left;
      }
    }
  }
}