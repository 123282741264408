

@font-face {
  font-family: 'SiemensSansRoman';
  src: url('../fonts/siemens/siemenssans-roman-webfont.eot');
  src: url('../fonts/siemens/siemenssans-roman-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/siemens/siemenssans-roman-webfont.woff') format('woff'), url('../fonts/siemens/siemenssans-roman-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SiemensSansBold';
  src: url('../fonts/siemens/siemenssans-bold-webfont.eot');
  src: url('../fonts/siemens/siemenssans-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/siemens/siemenssans-bold-webfont.woff') format('woff'), url('../fonts/siemens/siemenssans-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}