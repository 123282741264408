@import './scss/fonts';

@import "scss/_vars";
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

@import "scss/mixin-navbar";
@import "scss/mixin-base";
@import "scss/mixin-buttons";

@import "~uikit/src/scss/uikit.scss";

@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~plyr/dist/plyr.css';

@import 'scss/unity';
@import 'scss/img-single';
@import 'scss/navbar';
@import 'scss/cards_add_resources';
@import 'scss/headings';
@import 'scss/qrcode-block';
@import 'scss/footer';

/* vizy */

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}