.card-resource--link{
  max-width:600px;
  margin:0 auto;
  display: block;
  .uk-card-media-top img{
    transform: scale(1);
    transition: all 0.5s ease;
    will-change: auto;
  }
  h3,p{
    text-decoration: none;
    color:$global-inverse-color;
  }
  h3{
    font-size: 1.33rem;
  }
  svg{
    width:36px;
    height:36px;
    display: inline-block;
  }
  svg path{
    fill:$si-teal3;
  }
  &:hover,
  &:active,
  &:focus{
    text-decoration: none;
    .card-resource{
      background: rgba(0, 204, 204, 0.2)
    }
    .uk-card-media-top img{
      transform: scale(1.25);
      transition: all 0.5s ease;
    }
    h3{
      color:$si-teal2;
      text-decoration: none;
    }
    p{
      text-decoration: none;
    }
    svg path{
      fill:$si-teal2;
    }

  }

  .card-resource{
    background:rgba(0,204,204,0.12);
    position: relative;
    .uk-card-media-top{
      aspect-ratio: 600 / 400;
      overflow: hidden;
    }
    .uk-card-body{
      padding:32px 24px 76px 24px;
    }
    .uk-card-footer{
      position: absolute;
      bottom:0;
      right:0;
      width:100%;
      box-sizing: border-box;
    }

  }
}

