/*
1. regular modal and text
2. quote
3. video modal - mobile landscape
 */
/*
1. regular modal and text
-----------------------------------------------------------
 */
.unity-container {
  margin: 70px 0;
  position: relative;

  .unity-canvas {
    width: 100%;
    height: 80vh;
  }
  .unity-controls {
    position: absolute;
    left: 0; bottom: 0;
    right: 0;
    text-align: center;
    pointer-events:none;
    
    * {
      pointer-events:all;
    }
  }
  .unity-controls .uk-button{
    padding: 8px 16px;
  }
  .border-top-left {
    position: absolute;
    left: 0; top: 0;
    width: 100px; height: 100px;
    border-top: 1px solid white;
    border-left: 1px solid white;
    pointer-events:none;
  }
  .border-top-right {
    position: absolute;
    right: 0; top: 0;
    width: 100px; height: 100px;
    border-top: 1px solid white;
    border-right: 1px solid white;
    pointer-events:none;
  }
  .border-bottom-left {
    position: absolute;
    left: 0; bottom: 0;
    width: 100px; height: 100px;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    pointer-events:none;
  }
  .border-bottom-right {
    position: absolute;
    right: 0; bottom: 0;
    width: 100px; height: 100px;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    pointer-events:none;
  }
  .unity-popup {
    position: absolute;
    top: 50px;
    bottom: 50px;
    //right: 50px;
    //overflow: auto;
    width:100%;
    padding: 15px;
    background: rgba(0,0,40,0.75);
    border: 1px solid $si-teal3;
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    @media screen and (orientation: landscape) and (max-width: $breakpoint-medium){
      top:0;
      bottom:0;
    }

    @media screen and (min-width: $breakpoint-medium){
      padding: 25px;
      width: calc(75ch + 50px);
      left:50%;
      transform: translateX(-50%);
    }
    .unity-popup--top{

    }
    .unity-popup--content{
      //max-width:75ch;
      padding: 0 20px 0 0;
      overflow: auto;
      scrollbar-color: $si-teal3 $si-dark-blue;
      scrollbar-width: thin;
      p{
        max-width:75ch;
        font-size:0.888rem;
        @media screen and (min-width: $breakpoint-medium){
          font-size:1rem;
        }
      }
      &::-webkit-scrollbar{
        width:1rem;
      }
      &::-webkit-scrollbar-track{
        background-color: $si-dark-blue;
      }
      &::-webkit-scrollbar-thumb{
        background-color: $si-teal3;
      }
      @media screen and (min-width: $breakpoint-medium){
        padding: 0 40px 0 0;
      }
    }

    .unity-popup-close-button {
      position: absolute;
      right: 15px;
      top: 15px;
      background: none;
      color: white;
      border: 0;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active{
        color:$si-teal2;
      }
      @media screen and (min-width: $breakpoint-medium){
        right: 25px;
        top: 25px;
      }
    }

  }
  .unity-popup-text .unity-popup--content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

}
/* LINK COLOR TEXT */
.unity-popup-text{
  a{
    color:$si-teal2;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus{
      text-decoration: underline;
      color:$si-teal3;
    }
  }
}

/*
.uk-button.uk-button-primary {
  background: rgb(0,254,185);
  background: linear-gradient(149deg, rgba(0,254,185,1) 0%, rgba(0,230,219,1) 100%);
  color: #000028;
}*/

/*
2. quote
--------------------------------------------------------------------------------------
 */
.unity-popup-quote{
  .unity-popup--content{
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a{
      color:$si-teal2;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus{
        text-decoration: underline;
        color:$si-teal3;
      }
    }
  }
  .blockquote{
    position: relative;
    margin-top:48px;
    text-decoration: none;
    font-style: normal;
    &:before{
      position: absolute;
      content:'';
      top:-48px;
      left:0;
      height: 48px;
      width: 32px;
      background:url(../images/quote.svg);
      background-position: 0 0;
      background-size: 32px 44px;
      background-repeat: no-repeat;
    }
    p{
      font-size:1.25rem !important;
    }
  }
  .blockquote-footer{
    color:$global-inverse-color;
    text-decoration: none;
    font-style: normal;
  }

}
@media screen and (max-width:1024px){
  .unity-popup-quote .unity-popup--content {
    justify-content: start;
  }
}



/*
3. video modal
--------------------------------------------------------------------------------------
 */

.unity-container .unity-popup-video{
  .unity-popup--content{
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden !important;
  }
}
@media screen and (orientation: landscape) and (max-width:1024px){
  .unity-popup-video .unity-popup--content{
    overflow: hidden;
  }
  .unity-popup-video .unity-popup--content .plyr{
    max-height:100%;
    max-width:70%;
    margin:0 auto;
    display:block;
  }
}