/* SIEMENS COLORS*/
$si-dark-blue: #000028;
$si-teal: #fff;
$si-orange: #fd9400;
//$si-btn-hover: #00557C;
$si-btn-hover:#c5ffef;
$si-teal2: #00ffb9;
$si-teal3: #009999;
/* UI KIT COLOR */
$global-primary-background: $si-dark-blue;
$global-emphasis-color: $si-teal;
$global-inverse-color: #FFFFFF;
$base-body-color: $global-inverse-color;
$global-background:$si-dark-blue;
  /* FONTS */
$global-font-family: 'SiemensSansRoman', sans-serif;
$base-body-font-family: $global-font-family;
$base-heading-font-family: 'SiemensSansBold', sans-serif;

/* BASE TEXT */
$global-font-size: 18px;
$global-line-height:1.55;

$base-h1-font-size:2.5rem;
$base-h2-font-size:2rem;
$base-h3-font-size:1.75rem;
$base-h4-font-size:1.5rem;

/* NAVBAR */
$navbar-nav-item-text-transform: none;
/*$navbar-item-padding-horizontal: 32px;*/
$navbar-nav-item-padding-horizontal: 2em;
$navbar-nav-item-font-size:0.875rem;
$navbar-nav-item-font-family:$base-heading-font-family;
$navbar-nav-item-color: white;
$navbar-nav-item-hover-color:$si-teal;
$navbar-nav-item-onclick-color: $si-teal;
$navbar-nav-item-active-color: $si-teal;
/* NAVBAR DROPDOWN */
$navbar-dropdown-nav-item-color: $global-inverse-color;
$navbar-dropdown-nav-item-hover-color: $si-teal2;
$navbar-dropdown-nav-item-active-color: $si-teal2;
$navbar-dropdown-width:250px;
$navbar-dropdown-margin: 0;
$navbar-dropdown-shift-margin: 0;
$navbar-dropdown-viewport-margin: 0;
/* BUTTONS */
//$button-line-height: 53px;
//$button-large-line-height: 88px;
//$button-font-size:  1.13125rem;
$button-font-size:  1.111111111111111rem;
$button-large-font-size: 1.25rem;


$button-default-background:$global-inverse-color;
$button-default-color:$si-dark-blue;
$button-default-hover-background:$si-teal2;
$button-default-hover-color:$si-dark-blue;

//$button-primary-background: linear-gradient(149deg, rgba(0,254,185,1) 0%, rgba(0,230,219,1) 100%);
$button-primary-background: transparent;
$button-primary-color: $si-dark-blue;
$button-primary-hover-background:  $si-btn-hover;

$button-primary-hover-color: $si-dark-blue;
$button-primary-active-background:  $si-btn-hover;
$button-primary-active-color: $si-dark-blue;

$button-secondary-background: transparent;
$button-secondary-color: $global-inverse-color;
$button-secondary-hover-background: $si-btn-hover ;
$button-secondary-hover-color: $global-inverse-color;
$button-secondary-active-background: $si-btn-hover;
$button-secondary-active-color: $global-inverse-color;

/* LABEL - for cards */
$label-background: transparent;
$label-color: $si-teal;
$label-font-size: 1.25rem;
$label-padding-horizontal:0;

/* CARDS - customer stories */
$card-default-background: #FFFFFF;
$card-default-color: $si-dark-blue;
$card-default-title-color: $si-dark-blue;
$card-default-hover-background:#FFFFFF;